
:root {
  --overlay-bg: #1867c0;
  --transition-duration: .35s;
}

.fade-enter-active[data-v-4c22b934],
.fade-leave-active[data-v-4c22b934] {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter[data-v-4c22b934],
.fade-leave-active[data-v-4c22b934] {
  opacity: 0;
}
@keyframes fadeInDown {
from {
    transform: translate3d(0, -40px, 0);
}
to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
}
.fade-in-down-leave-to[data-v-4c22b934] {
  opacity: 0;
  transition: opacity .3s;
}
.fade-in-down-enter[data-v-4c22b934] {
  opacity: 0;
  transform: translate3d(0, -40px, 0);
}
.fade-in-down-enter-to[data-v-4c22b934] {
  opacity: 0;
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
}
@keyframes fadeInRight {
from {
    transform: translate3d(40px, 0, 0);
}
to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
}
.fade-in-right-leave-to[data-v-4c22b934] {
  opacity: 0;
  transition: opacity .3s;
}
.fade-in-right-enter[data-v-4c22b934] {
  opacity: 0;
  transform: translate3d(40px, 0, 0);
}
.fade-in-right-enter-to[data-v-4c22b934] {
  opacity: 0;
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
}
@keyframes fadeInUp {
from {
    transform: translate3d(0, 40px, 0);
}
to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
}
.fade-in-up-leave-to[data-v-4c22b934] {
  opacity: 0;
  transition: opacity .3s;
}
.fade-in-up-enter[data-v-4c22b934] {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}
.fade-in-up-enter-to[data-v-4c22b934] {
  opacity: 0;
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}
@keyframes fadeInLeft {
from {
    transform: translate3d(-40px, 0, 0);
}
to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
}
.fade-in-left-leave-to[data-v-4c22b934] {
  opacity: 0;
  transition: opacity .3s;
}
.fade-in-left-enter[data-v-4c22b934] {
  opacity: 0;
  transform: translate3d(-40px, 0, 0);
}
.fade-in-left-enter-to[data-v-4c22b934] {
  opacity: 0;
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
}
.zoom-enter-active[data-v-4c22b934],
.zoom-leave-active[data-v-4c22b934] {
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
}
.zoom-enter[data-v-4c22b934],
.zoom-leave-to[data-v-4c22b934] {
  opacity: 0;
  transform: scale(0);
}
.flip-x-enter-active[data-v-4c22b934],
.flip-x-leave-active[data-v-4c22b934] {
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
}
.flip-x-enter[data-v-4c22b934],
.flip-x-leave-to[data-v-4c22b934] {
  transform: rotateX(-180deg);
  opacity: 0;
}
.flip-y-enter-active[data-v-4c22b934],
.flip-y-leave-active[data-v-4c22b934] {
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
}
.flip-y-enter[data-v-4c22b934],
.flip-y-leave-to[data-v-4c22b934] {
  transform: rotateY(-180deg);
  opacity: 0;
}
.overlay-right[data-v-4c22b934] {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 0;
  background: var(--overlay-bg);
  transition-duration: var(--transition-duration);
}
.overlay-right-enter ~ .overlay-right[data-v-4c22b934],
.overlay-right-leave-to ~ .overlay-right[data-v-4c22b934] {
  width: 0;
}
.overlay-right-enter-active ~ .overlay-right[data-v-4c22b934],
.overlay-right-leave-active ~ .overlay-right[data-v-4c22b934] {
  width: 100vw;
}
.overlay-right-enter-active ~ .overlay-right[data-v-4c22b934] {
  transition-timing-function: ease-in;
}
.overlay-right-leave-active ~ .overlay-right[data-v-4c22b934] {
  transition-timing-function: ease-out;
}
.overlay-right-enter-active[data-v-4c22b934],
.overlay-right-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
.overlay-top[data-v-4c22b934] {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;
  background: var(--overlay-bg);
  transition-duration: var(--transition-duration);
}
.overlay-down-enter ~ .overlay-top[data-v-4c22b934],
.overlay-down-leave-to ~ .overlay-top[data-v-4c22b934] {
  height: 0;
}
.overlay-down-enter-active ~ .overlay-top[data-v-4c22b934],
.overlay-down-leave-active ~ .overlay-top[data-v-4c22b934] {
  height: 100vh;
}
.overlay-down-enter-active ~ .overlay-top[data-v-4c22b934] {
  transition-timing-function: ease-in;
}
.overlay-down-leave-active ~ .overlay-top[data-v-4c22b934] {
  transition-timing-function: ease-out;
}
.overlay-down-enter-active[data-v-4c22b934],
.overlay-down-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
.overlay-bottom[data-v-4c22b934] {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100vw;
  background: var(--overlay-bg);
  transition-duration: var(--transition-duration);
}
.overlay-up-enter ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-leave-to ~ .overlay-bottom[data-v-4c22b934] {
  height: 0;
}
.overlay-up-enter-active ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-leave-active ~ .overlay-bottom[data-v-4c22b934] {
  height: 100vh;
}
.overlay-up-enter-active ~ .overlay-bottom[data-v-4c22b934] {
  transition-timing-function: ease-in;
}
.overlay-up-leave-active ~ .overlay-bottom[data-v-4c22b934] {
  transition-timing-function: ease-out;
}
.overlay-up-enter-active[data-v-4c22b934],
.overlay-up-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
.overlay-left[data-v-4c22b934] {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 0;
  background: var(--overlay-bg);
  transition-duration: .35s;
}
.overlay-left-enter ~ .overlay-left[data-v-4c22b934],
.overlay-left-leave-to ~ .overlay-left[data-v-4c22b934] {
  width: 0;
}
.overlay-left-enter-active ~ .overlay-left[data-v-4c22b934],
.overlay-left-leave-active ~ .overlay-left[data-v-4c22b934] {
  width: 100vw;
}
.overlay-left-enter-active ~ .overlay-left[data-v-4c22b934] {
  transition-timing-function: ease-in;
}
.overlay-left-leave-active ~ .overlay-left[data-v-4c22b934] {
  transition-timing-function: ease-out;
}
.overlay-left-enter-active[data-v-4c22b934],
.overlay-left-leave-active[data-v-4c22b934] {
  transition-duration: .35s;
}
.overlay-up-full-enter ~ .overlay-bottom[data-v-4c22b934] {
  height: 100vh;
}
.overlay-up-full-enter-active ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-full-leave-active ~ .overlay-bottom[data-v-4c22b934] {
  height: 100vh;
}
.overlay-up-full-enter-active ~ .overlay-bottom[data-v-4c22b934] {
  transition-timing-function: ease-in;
}
.overlay-up-full-enter-active ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-full-enter-to ~ .overlay-bottom[data-v-4c22b934] {
  display: none;
}
.overlay-up-full-enter-to ~ .overlay-top[data-v-4c22b934] {
  height: 0;
}
.overlay-up-full-leave-active ~ .overlay-top[data-v-4c22b934] {
  transition-timing-function: ease;
}
.overlay-up-full-enter ~ .overlay-top[data-v-4c22b934],
.overlay-up-full-enter-active ~ .overlay-top[data-v-4c22b934],
.overlay-up-full-enter-to ~ .overlay-top[data-v-4c22b934] {
  transition-duration: unset !important;
  height: 100vh;
}
.overlay-up-full-enter-active[data-v-4c22b934],
.overlay-up-full-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
.overlay-right-full-enter ~ .overlay-left[data-v-4c22b934] {
  width: 100vw;
}
.overlay-right-full-enter-active ~ .overlay-left[data-v-4c22b934],
.overlay-right-full-leave-active ~ .overlay-left[data-v-4c22b934] {
  width: 100vw;
}
.overlay-right-full-enter-active ~ .overlay-left[data-v-4c22b934] {
  transition-timing-function: ease-in;
}
.overlay-right-full-enter-active ~ .overlay-left[data-v-4c22b934],
.overlay-right-full-enter-to ~ .overlay-left[data-v-4c22b934] {
  display: none;
}
.overlay-right-full-enter-to ~ .overlay-right[data-v-4c22b934] {
  width: 0;
}
.overlay-right-full-leave-active ~ .overlay-right[data-v-4c22b934] {
  transition-timing-function: ease;
}
.overlay-right-full-enter ~ .overlay-right[data-v-4c22b934],
.overlay-right-full-enter-active ~ .overlay-right[data-v-4c22b934],
.overlay-right-full-enter-to ~ .overlay-right[data-v-4c22b934] {
  transition-duration: unset !important;
  width: 100vw;
}
.overlay-right-full-enter-active[data-v-4c22b934],
.overlay-right-full-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
.overlay-down-full-enter ~ .overlay-top[data-v-4c22b934] {
  height: 100vh;
}
.overlay-down-full-enter-to ~ .overlay-bottom[data-v-4c22b934] {
  height: 0;
}
.overlay-down-full-enter-active ~ .overlay-top[data-v-4c22b934],
.overlay-down-full-leave-active ~ .overlay-top[data-v-4c22b934] {
  height: 100vh;
}
.overlay-down-full-enter-active ~ .overlay-top[data-v-4c22b934] {
  transition-timing-function: ease-in;
}
.overlay-down-full-leave-active ~ .overlay-bottom[data-v-4c22b934] {
  transition-timing-function: ease;
}
.overlay-down-full-enter-active[data-v-4c22b934],
.overlay-down-full-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
.overlay-down-full-enter-active ~ .overlay-top[data-v-4c22b934],
.overlay-down-full-enter-to ~ .overlay-top[data-v-4c22b934] {
  display: none;
}
.overlay-down-full-enter ~ .overlay-bottom[data-v-4c22b934],
.overlay-down-full-enter-active ~ .overlay-bottom[data-v-4c22b934],
.overlay-down-full-enter-to ~ .overlay-bottom[data-v-4c22b934] {
  transition-duration: unset !important;
  height: 100vh;
}
.overlay-left-full-enter ~ .overlay-right[data-v-4c22b934] {
  width: 100vw;
}
.overlay-left-full-enter-active ~ .overlay-right[data-v-4c22b934],
.overlay-left-full-leave-active ~ .overlay-right[data-v-4c22b934] {
  width: 100vw;
}
.overlay-left-full-enter-active ~ .overlay-right[data-v-4c22b934] {
  transition-timing-function: ease-in;
}
.overlay-left-full-enter-active ~ .overlay-right[data-v-4c22b934],
.overlay-left-full-enter-to ~ .overlay-right[data-v-4c22b934] {
  display: none;
}
.overlay-left-full-enter-to ~ .overlay-left[data-v-4c22b934] {
  width: 0;
}
.overlay-left-full-leave-active ~ .overlay-left[data-v-4c22b934] {
  transition-timing-function: ease;
}
.overlay-left-full-enter ~ .overlay-left[data-v-4c22b934],
.overlay-left-full-enter-active ~ .overlay-left[data-v-4c22b934],
.overlay-left-full-enter-to ~ .overlay-left[data-v-4c22b934] {
  transition-duration: unset !important;
  width: 100vw;
}
.overlay-left-full-enter-active[data-v-4c22b934],
.overlay-left-full-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
.overlay-up-down-enter ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-down-enter ~ .overlay-top[data-v-4c22b934] {
  height: 0;
}
.overlay-up-down-leave-active ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-down-leave-to ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-down-leave-to ~ .overlay-top[data-v-4c22b934],
.overlay-up-down-leave-to ~ .overlay-top[data-v-4c22b934] {
  height: 51vh;
}
.overlay-up-down-enter-active ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-down-enter-active ~ .overlay-top[data-v-4c22b934] {
  transition-timing-function: ease;
}
.overlay-up-down-leave-active ~ .overlay-bottom[data-v-4c22b934],
.overlay-up-down-leave-active ~ .overlay-top[data-v-4c22b934] {
  transition-timing-function: ease;
}
.overlay-up-down-enter-active[data-v-4c22b934],
.overlay-up-down-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
.overlay-left-right-enter ~ .overlay-left[data-v-4c22b934],
.overlay-left-right-enter ~ .overlay-right[data-v-4c22b934] {
  width: 0;
}
.overlay-left-right-leave-active ~ .overlay-left[data-v-4c22b934],
.overlay-left-right-leave-to ~ .overlay-left[data-v-4c22b934],
.overlay-left-right-leave-to ~ .overlay-right[data-v-4c22b934],
.overlay-left-right-leave-to ~ .overlay-right[data-v-4c22b934] {
  width: 51vw;
}
.overlay-left-right-enter-active ~ .overlay-left[data-v-4c22b934],
.overlay-left-right-enter-active ~ .overlay-right[data-v-4c22b934] {
  transition-timing-function: ease;
}
.overlay-left-right-leave-active ~ .overlay-left[data-v-4c22b934],
.overlay-left-right-leave-active ~ .overlay-right[data-v-4c22b934] {
  transition-timing-function: ease;
}
.overlay-left-right-enter-active[data-v-4c22b934],
.overlay-left-right-leave-active[data-v-4c22b934] {
  transition-duration: var(--transition-duration);
}
